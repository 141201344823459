import { NgModule } from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {OauthComponent} from "./components/oauth/oauth.component";
import {L1AuthGuard, L3AuthGuard} from "ngx-auth-util-lib";

const routes: Routes = [
  {
    path: 'oauth',
    component: OauthComponent,
  },
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
    canActivate: [L1AuthGuard, L3AuthGuard]
  },
  {path: '', redirectTo: 'pages', pathMatch: 'full'},
  {path: '**', redirectTo: 'pages'}
];


const config: ExtraOptions = {
  useHash: false,
  scrollPositionRestoration: 'enabled',
  paramsInheritanceStrategy: 'always' //TODO keep eye on
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
