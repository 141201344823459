import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {L3_AUTHORITIES} from "../models/token";
import {L1L3AuthService} from "ngx-auth-util-lib";

@Directive({
  selector: '[l2Permissions]'
})
export class PermissionsDirective {

  constructor(private templateRef: TemplateRef<any>,
              private viewContainerRef: ViewContainerRef,
              private authService: L1L3AuthService) {
  }

  _permissions: L3_AUTHORITIES[];
  _hasView: boolean = false;

  get l2Permissions(): L3_AUTHORITIES[] {
    return this._permissions;
  }

  @Input()
  set l2Permissions(permissions: L3_AUTHORITIES[]) {
    if (!permissions || !permissions.length) {
      //permissions list is empty
    } else {
      this._permissions = [];
      this._permissions = permissions;

      const access = this.authService.checkPermissions(permissions);

      if (access && !this._hasView) {
        this.viewContainerRef.createEmbeddedView(this.templateRef);
        this._hasView = true;
      } else if (!access && this._hasView) {
        this.viewContainerRef.clear();
        this._hasView = false;
      }
    }
  }

}
