import * as deepmerge from 'deepmerge';
import {ENVIRONMENT_GLOBAL} from '../global_env';

export const environment = deepmerge(ENVIRONMENT_GLOBAL, {
    envName: 'dev',
    production: true,
    redirect: {
      host: 'https://dev-administration-admin.meinhplus.de'
    },
    auth_L1: {
      client_id: 'iamlevel2-support',
      scope: 'phone email profile openid',
      endpoint: {
        host: 'https://dev-account.meinhplus.de',
        paths: {
          prefix: '/auth/realms/hekatron-tuer-tor'
        }
      }
    },
    backend: {
      scope: 'l2m',
      client_id: 'l2m-adminapp-wMDg1yZyVyVfmJFA',
      endpoint: {
        host: 'https://api.dev-administration.meinhplus.de'
      }
    },
    files: {
      paths: {
        host: 'https://dev-administration-admin.meinhplus.de',
      }
    },
    links: {
      applications: {
        mh2: 'https://dev.meinhplus.de',
        hcp: 'https://dev-dashboard.meinhplus.de'
      }
    }
  });
