export enum ERROR_CODES {
  USERS_USER_NOT_FOUND = 1002,
  USERS_ROLE_NOT_FOUND = 1003,
  USERS_APP_NOT_FOUND = 1004,
  USERS_INVITATION_PENDING = 1006,
  USERS_INVITATION_NOT_FOUND = 1007,
  USERS_INVITATION_STATE = 1008,
  USERS_ALREADY_EXIST = 1010,
  USERS_LAST_ADMIN = 1011,
  USERS_NO_PROTECTED_APP_ACCESS = 1012,
  USERS_CSV_CREATION_FAILED = 1013,

  USERS_INV_USER_ALREADY_EXIST_IN_SYSTEM = 1101,
  USERS_INV_INVITATION_PENDING = 1102,
  USERS_INV_ROLE_NOT_FOUND = 1104,

  GROUPS_USER_NOT_FOUND = 2001,
  GROUPS_APP_NOT_FOUND = 2002,
  GROUPS_NO_PROTECTED_APP_ACCESS = 2004,
  GROUPS_GROUP_NOT_FOUND = 2005,
  GROUPS_USER_NOT_ASSIGNED_TO_GROUP = 2006,

  REQUEST_SIZE_EXCEEDED = 7006,

  DOMAIN_COMPANY_DATA_ALREADY_EXISTS = 7200,
  DOMAIN_COMPANY_DATA_NOT_FOUND = 7201,
  DOMAIN_COMPANY_LOGO_FILE_NOT_FOUND = 7202,
  DOMAIN_COMPANY_FILE_DESCR_NOT_FOUND = 7203,
  DOMAIN_COMPANY_LOGO_FILE_ALREADY_ATTACHED = 7204,
  DOMAIN_COMPANY_LOGO_FILE_SIZE = 7205,
  DOMAIN_COMPANY_LOGO_FORMAT_NOT_SUPPORTED = 7206,
  DOMAIN_COMPANY_USER_NOT_FOUND = 7207,
  DOMAIN_COMPANY_USER_CONTRACT_NOT_ACCEPTED = 7208,
  DOMAIN_COMPANY_DPO_NOT_PROVIDED = 7209,

  ADMIN_USERS_USER_NOT_FOUND = 11002,
  ADMIN_USERS_ROLE_NOT_FOUND = 11003,
  // ADMIN_USERS_APP_NOT_FOUND = 11004,
  // ADMIN_USERS_INVITATION_PENDING = 11006,
  ADMIN_USERS_INVITATION_NOT_FOUND = 11007,
  // ADMIN_USERS_INVITATION_STATE = 11008,
  // ADMIN_USERS_ALREADY_EXIST = 11010,
  // ADMIN_USERS_LAST_ADMIN = 11011,
  // ADMIN_USERS_NO_PROTECTED_APP_ACCESS = 11012,
  // ADMIN_USERS_CSV_CREATION_FAILED = 11013,

  ADMIN_USERS_INV_USER_ALREADY_EXIST_IN_SYSTEM = 11101,
  ADMIN_USERS_INV_INVITATION_PENDING = 11102,
  ADMIN_USERS_INV_ROLE_NOT_FOUND = 11104,
}
