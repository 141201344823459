import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ApplicationIds } from 'src/app/models/application';

// TODO: should be moved to the design-lib (MH-2313).
@Component({
  selector: 'ngx-l2-application-img',
  templateUrl: './application-img.component.html',
  styleUrl: './application-img.component.scss'
})
export class ApplicationImgComponent implements OnChanges {
  @Input() applicationId: ApplicationIds;
  appImgPath: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['applicationId']) {
      this.setApplicationImg();
    }
  }

  private setApplicationImg() {
    switch (this.applicationId) {
      case ApplicationIds.HPLUS_SERVICE:
        this.appImgPath = 'assets/images/hekatron-icons/office.png';
        break;
      case ApplicationIds.HPLUS_DONGLE:
        this.appImgPath = 'assets/images/hekatron-icons/dongle.png';
        break;
      case ApplicationIds.TENDER_TOOL:
        this.appImgPath = 'assets/images/hekatron-icons/ausschreibung.png';
        break;
      case ApplicationIds.ELECTRICITY_CALCULATION_TOOL:
        this.appImgPath = 'assets/images/hekatron-icons/stromberechnung.png';
        break;
      case ApplicationIds.OMS_TOOL:
        this.appImgPath = 'assets/images/hekatron-icons/oms.png';
        break;
      case ApplicationIds.CUSTOMER_PORTAL:
      case ApplicationIds.LEVEL_2:
        this.appImgPath = 'assets/images/hekatron-icons/dashboard.png';
        break;
      case ApplicationIds.REMOTE_ADMIN:
      case ApplicationIds.REMOTE_DESKTOP:
        this.appImgPath = 'assets/images/hekatron-icons/fernzugriff.png';
        break;
      default:
        break;
    }
  }
}
