<div *ngIf="loading" class="loading-container">
  <mat-spinner [diameter]="40"></mat-spinner>
  <b class="ms-4">{{loginDescription}}</b>
</div>
<div *ngIf="error != null" class="p-4">
  <h4 class="primary-color">{{'OAUTH.ERROR.TITLE' | translate}}</h4>
  <p>{{'OAUTH.ERROR.DESCRIPTION' | translate}}</p>
  <p *ngIf="invalidCodeMessage">Error: {{error}}</p>
  <button mat-stroked-button color="primary" (click)="authService.logout()">{{'OAUTH.LOGIN_BTN' | translate}}</button>
</div>
