import {L3_AUTHORITIES} from "./token";

export interface IMenuItem {
  icon?: any;
  translationKey: string;
  route: string;
  required_authorities?: L3_AUTHORITIES[];
}

export const MAIN_MENU_ITEMS : IMenuItem[] = [{
  route: '/pages/domains',
  translationKey: 'MENUS.MAIN_MENU.DOMAINS',
  required_authorities: [L3_AUTHORITIES.ADMIN_DOMAIN_MGMT]
},{
  route: '/pages/users',
  translationKey: 'MENUS.MAIN_MENU.USERS',
  required_authorities: [L3_AUTHORITIES.ADMIN_DOMAIN_USERS_MGMT, L3_AUTHORITIES.ADMIN_USERS_MGMT]
}];

export const ADMIN_MENU_ITEMS: IMenuItem[] = [
  {
    icon: 'people_outline',
    translationKey: 'MENUS.ADMIN.ADMINS',
    route:'/pages/admin/admin-users',
    required_authorities: [L3_AUTHORITIES.ADMIN_USERS_MGMT]
  }
];
