import {AfterViewInit, Component, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {AccountMenuItem, DefaultMenuItem} from "ngx-heka-design-lib";
import {environment} from "../../../environments/local/environment";
import {HttpClient} from "@angular/common/http";
import {LoadingService} from "../../services/loading/loading.service";
import {NotificationService} from "../../services/error-handling/notification.service";
import {Overlay} from "@angular/cdk/overlay";
import {TranslateService} from "@ngx-translate/core";
import {L1L3AuthService, UserInfo} from "ngx-auth-util-lib";
import {Subscription} from "rxjs";
import {MatTabNavPanel} from "@angular/material/tabs";
import {L3_AUTHORITIES} from "../../models/token";
import {AdminUserMgmtService} from "../../services/admin-user-mgmt.service";
import {ADMIN_MENU_ITEMS, IMenuItem, MAIN_MENU_ITEMS} from "../../models/menu";

@Component({
  selector: 'ngx-l2-menu',
  templateUrl: './l2-menu.component.html',
  styleUrls: ['./l2-menu.component.scss']
})
export class L2MenuComponent implements OnInit, OnDestroy {
  private loading = false;
  private subscriptions = new Subscription();
  @HostBinding('class.z-index-2000') zIndex2000: boolean = false;
  @Input() tabPanel: MatTabNavPanel;

  mainMenuItems: DefaultMenuItem[] = [];
  accountMenu: AccountMenuItem[] = [];
  adminMenuItems = ADMIN_MENU_ITEMS;

  env = environment;
  adminMenuPermissions: L3_AUTHORITIES[] = [L3_AUTHORITIES.ADMIN_USERS_MGMT];
  userInfo: UserInfo;

  constructor(private http: HttpClient,
              private loadingService: LoadingService,
              private notificationService: NotificationService,
              private overlay: Overlay,
              private adminUserMgmtService: AdminUserMgmtService,
              private translateService: TranslateService,
              public authService: L1L3AuthService) {
  }

  ngOnInit(): void {
    this.initLoadingSubscription();
    this.setupAccountMenu();
    this.getProfile();
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
    this.loading = false;
  }

  private setupAccountMenu() {
    const newAccountMenu = [];
    newAccountMenu.push({
      matIcon: 'manage_accounts',
      title: this.translateService.instant('MENUS.ACCOUNT_MENU.PROFILE'),
      clickHandler: () => this.authService.goToProfile()
    });
    newAccountMenu.push({
      matIcon: 'logout',
      title: this.translateService.instant('MENUS.ACCOUNT_MENU.LOGOUT'),
      clickHandler: () => this.authService.logout()
    })

    this.accountMenu = newAccountMenu;
  }

  private getProfile() {
    this.subscriptions.add(this.adminUserMgmtService.userInfoChanged.subscribe({
      next: userInfo => {
        if (userInfo && userInfo.authorities) {
          this.userInfo = userInfo;

          this.createMainMenu(this.userInfo);//later when menu changes, add to createMenu()
        }
      }
    }));
  }

  private createMainMenu(userInfo: UserInfo) {
    this.mainMenuItems =
      MAIN_MENU_ITEMS
        .filter(userMenuItem => this.hasAuthority(userMenuItem, userInfo.authorities))
        .map(item => {
          return {
            route: item.route,
            cssClass: '',
            title: this.translateService.instant(item.translationKey),
            hidden: false
          };
        });
  }

  private hasAuthority(item: IMenuItem, authorities: string[]): boolean {
    return !item.required_authorities || item.required_authorities.some(r => authorities.includes(r));
  }


  private initLoadingSubscription() {
    this.subscriptions.add(this.loadingService.getLoading().asObservable().subscribe({
      next: value => {
        if (value && this.loading) {
          return;
        }
        if (value && !this.loading) {
          this.zIndex2000 = true;
        } else if (!value) {
          this.zIndex2000 = false;
        }
        this.loading = value;
      }
    }));
  }
}
