import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";
import {PermissionsDirective} from "./permissions.directive";

@NgModule({
  declarations: [
    PermissionsDirective
  ],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [
    PermissionsDirective
  ]
})

export class DirectivesModule {
}
