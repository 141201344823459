import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {L1L3AuthService, UserInfo} from "ngx-auth-util-lib";
import {HttpClient} from "@angular/common/http";
import {SharedDetailObject} from "../models/shared-detail-object";
import {
  AdminUserFilterParameters,
  IAdminUser,
  IAdminUserDetailDTO,
  IAdminUserListDTO,
  InviteAdminUserRequestData
} from "../models/admin-user";
import {PagedResponse} from "../models/pagedresponse";
import {environment} from "../../environments/local/environment";
import {IInviteUserResponseData, IRole} from "../models/user";
import {ERROR_CODES} from "../models/codes";
import {TranslateService} from "@ngx-translate/core";

const SUPPORT = '4e6e2312-8283-4df2-8aa5-6b0bb7aae9c6';
const SUPER_USER = 'e1167f03-fb57-4b81-8044-ae29ddb787b6';


@Injectable({
  providedIn: 'root'
})
export class AdminUserMgmtService {
  private _userInfoChanged: BehaviorSubject<UserInfo> = new BehaviorSubject<UserInfo>(null);
  private sharedAdminUser = new SharedDetailObject<IAdminUserDetailDTO>();
  private sharedAdminUserCount = new BehaviorSubject<number>(null);


  constructor(private http: HttpClient,
              private authService: L1L3AuthService,
              private translateService: TranslateService) {
    authService.isAuthenticatedSubject.subscribe({
      next: isAuthenticated => {
        if (isAuthenticated) {
          const userInfo = this.authService.getUserInfo();
          this.userInfoChanged.next(userInfo);
        }
      }
    });
  }

  get userInfoChanged(): BehaviorSubject<UserInfo> {
    return this._userInfoChanged;
  }

  public getAdminUsers(adminUserFilterParameters: AdminUserFilterParameters, sort?: string, sortDirection?: string)
    : Observable<PagedResponse<IAdminUserListDTO>> {
    const params = adminUserFilterParameters.generateRequestParameters();

    if (sort && sortDirection) {
      params['sort'] = this.sortDataAccessor(sort);
      params['direction'] = sortDirection;
    }

    return this.http.get<PagedResponse<IAdminUserListDTO>>(
      environment.backend.endpoint.host +
      environment.admin_users.endpoint.paths.prefix,
      {params});
  }

  public getAdminUserById(id: string): Observable<IAdminUser> {
    return this.http.get<IAdminUser>(
      environment.backend.endpoint.host +
      environment.admin_users.endpoint.paths.prefix + `/${id}`);
  }

  public deleteAdminUser(id: string): Observable<any> {
    return this.http.delete<any>(environment.backend.endpoint.host +
      environment.admin_users.endpoint.paths.prefix +
      `/${id}`);
  }

  public getAdminUserRoles(): Observable<IRole[]> {
    return this.http.get<IRole[]>(
      environment.backend.endpoint.host +
      environment.admin_users.endpoint.paths.prefix +
      environment.admin_users.endpoint.paths.roles);
  }

  public updateAdminUser(userId: string, roleIds: string[]): Observable<any> {
    return this.http.put<any>(environment.backend.endpoint.host +
      environment.admin_users.endpoint.paths.prefix +
      `/${userId}`, {roleIds});
  }

  public inviteAdminUser(inviteUserRequestData: InviteAdminUserRequestData): Observable<IInviteUserResponseData[]> {
    return this.http.post<IInviteUserResponseData[]>(environment.backend.endpoint.host +
      environment.admin_users.endpoint.paths.prefix +
      environment.admin_users.endpoint.paths.invitations, inviteUserRequestData);
  }

  public resendAdminUserInvitation(id: string)
    : Observable<any> {
    return this.http.post<any>(environment.backend.endpoint.host +
      environment.admin_users.endpoint.paths.prefix +
      environment.admin_users.endpoint.paths.invitations +
      `/${id}` + environment.admin_users.endpoint.paths.resend, {});
  }

  getSharedAdminUser(userId: string): Observable<IAdminUser | null> {
    if (this.sharedAdminUser.getId() !== userId) {
      this.updateSharedAdminUser(userId);
    }
    return this.sharedAdminUser.getSubject().asObservable();
  }

  updateSharedAdminUser(userId: string): void {
    this.sharedAdminUser.setId(userId);
    this.getAdminUserById(userId)
      .subscribe({
        next: user => {
          this.sharedAdminUser.updateSubject(user);
        },
        error: err => {
          this.sharedAdminUser.reset();
          this.sharedAdminUser.error(err);
        }
      });
  }

  resetSharedAdminUser(): void {
    this.sharedAdminUser.reset();
  }

  updateAdminUserCount(users: number): void {
    this.sharedAdminUserCount.next(users);
  }

  getAdminUserCount(): BehaviorSubject<number> {
    return this.sharedAdminUserCount;
  }

  resetAdminUserCount(): void {
    this.sharedAdminUserCount.next(null);
  }


  getRoleTranslationKeyById(roleId: string): string {
    switch (roleId) {
      case SUPPORT:
        return 'USERS.ROLES.TYPES.SUPPORT';
      case SUPER_USER:
        return 'USERS.ROLES.TYPES.SUPER_USER';
      default:
        return '';
    }
  }


  private sortDataAccessor(property: string): string {
    switch (property) {
      case 'fullName':
        return 'firstName, lastName';
      case 'userName':
        return 'username';
      default:
        return property;
    }
  }

  generateEmailValidationErrorMessage(errorCode: number, isSingularText: boolean): string {
    let message = ''
    switch (errorCode) {
      case ERROR_CODES.ADMIN_USERS_INV_USER_ALREADY_EXIST_IN_SYSTEM:
        if (isSingularText) {
          message = this.translateService.instant('USERS.ERRORS.USERS_INV_USER_ALREADY_EXIST_IN_SYSTEM_SINGULAR');
        } else {
          message = this.translateService.instant('USERS.ERRORS.USERS_INV_USER_ALREADY_EXIST_IN_SYSTEM_PLURAL');
        }
        break;
      case ERROR_CODES.ADMIN_USERS_INV_INVITATION_PENDING:
        if (isSingularText) {
          message = this.translateService.instant('USERS.ERRORS.USERS_INV_INVITATION_PENDING_SINGULAR');
        } else {
          message = this.translateService.instant('USERS.ERRORS.USERS_INV_INVITATION_PENDING_PLURAL');
        }
        break;
      case ERROR_CODES.ADMIN_USERS_INV_ROLE_NOT_FOUND:
        if (isSingularText) {
          message = this.translateService.instant('USERS.ERRORS.USERS_INV_ROLE_NOT_FOUND_SINGULAR');
        } else {
          message = this.translateService.instant('USERS.ERRORS.USERS_INV_ROLE_NOT_FOUND_PLURAL');
        }
        break;
      default:
        if (isSingularText) {
          message = this.translateService.instant('USERS.ERRORS.USERS_INVALID_INVITATION_SINGULAR');
        } else {
          message = this.translateService.instant('USERS.ERRORS.USERS_INVALID_INVITATION_PLURAL');
        }
        break;
    }
    return message;
  }
}
