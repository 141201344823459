export const ENVIRONMENT_GLOBAL = {
  envName: 'global',
  loggingEnabled: true,
  pageSize: 30,
  searchSelectPageSize: 10,
  debounceTime: 750,
  debounceTimeSearchSelect: 200,
  inputErrorSnackbarDuration: 3000,
  dialogs: {
    large: {
      width: '80vw',
      maxWidth: '1104px',
      minWidth: '350px'
    }
  },
  appVersion: require('../../package.json').version,
  redirect: {
    route: '/oauth'
  },
  auth_L1: {
    endpoint: {
      paths: {
        account: '/account',
        login: '/protocol/openid-connect/auth',
        token: '/protocol/openid-connect/token',
        certs: '/protocol/openid-connect/certs',
        logout: '/protocol/openid-connect/logout',
        register: '/protocol/openid-connect/registrations'
      }
    }
  },
  backend: {
    endpoint: {
      paths: {
        prefix: '/api',
        token: '/auth/token',
        logout: '/auth/logout',
      }
    }
  },
  files: {
    paths: {
      prefix: '/docs',
      filePath: {}
    }
  },
  imprint: {
    endpoint: {
      paths: {
        imprint: '/impressum'
      }
    }
  },
  links: {
    data_security: 'https://www.hekatron-brandschutz.de/datenschutz',
    help: 'https://www.meinhplus.de/wissenswertes/tutorials/fuer-feststellanlagen-und-andere-gewerke',
    marketing: 'https://www.hekatron-brandschutz.de/kontakt',
    homepage: 'https://www.meinhplus.de/',
    email: 'info@hekatron.de',
    applications: {
      elec_calc_tool: 'https://www.hekatron-planungstools.de/stromberechnung.php',
      tender_tool: 'https://www.hekatron-planungstools.de/Extend.php'
    }
  },
  domains: {
    endpoint: {
      paths: {
        prefix: '/api/admin/domains',
        sanctioned: '/sanctioned'
      }
    }
  },
  admin_users: {
    endpoint: {
      paths: {
        prefix: '/api/users/admin',
        roles: '/roles',
        overview: "/overview",
        exports: "/exports",
        profile: "/profile",
        invitations: "/invitations",
        csv: "/csv",
        resend: "/resend",
        onboardingCompleted: '/onboarding-completed'
      }
    }
  },
  domain: {
    endpoint: {
      paths: {
        prefix: '/api/admin/domain',
        applications: '/applications',
        roles: '/roles',
        users: '/users',
        invitations: '/invitations',
        resend: '/resend',
        groups: '/groups'
      }
    }
  },
  usergroups: {
    endpoint: {
      paths: {
        prefix: '/api/usergroups',
        users: "/users"
      }
    }
  }
};
