<ngx-heka-menu-bar [defaultMenuItems]="mainMenuItems" [accountMenuItems]="accountMenu"
                   [userEmail]="userInfo?.email">
  <img src="assets/images/hekatron-icons/dashboard.png" routerLink="'/pages'" menu-img>
  <button class="ms" id="sel-admin-menu-btn" mat-icon-button [matMenuTriggerFor]="adminMenu"
          *l2Permissions="adminMenuPermissions" settings-btn>
    <mat-icon class="material-icons-outlined">settings</mat-icon>
  </button>
</ngx-heka-menu-bar>
<mat-menu #adminMenu="matMenu">
  <!--        <button class="mat-menu-title" mat-menu-item disabled>{{'ADMIN_MENU.TITLE' | translate}}</button>-->
  <button *ngFor="let menuItem of adminMenuItems" mat-menu-item [routerLink]='menuItem.route' class="sel-admin-menu">
    <mat-icon *ngIf="menuItem?.icon" class="material-icons-outlined">{{menuItem.icon}}
    </mat-icon>
    <span>{{menuItem.translationKey | translate}}</span>
  </button>
</mat-menu>
