import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {L1L3AuthService, TokenRefreshService} from "ngx-auth-util-lib";
import {BehaviorSubject, filter, take} from "rxjs";

@Component({
  selector: 'app-oauth',
  templateUrl: './oauth.component.html',
  styleUrls: ['./oauth.component.scss']
})
export class OauthComponent implements OnInit {
  public error: string;
  public loginDescription: string;
  public loading: boolean = false;
  public invalidCodeMessage: boolean;
  private refreshTokenSubject: BehaviorSubject<any>;

  constructor(private route: ActivatedRoute,
              private router: Router,
              public authService: L1L3AuthService,
              private tokenRefreshService: TokenRefreshService,
              private translate: TranslateService) {
    this.refreshTokenSubject = this.tokenRefreshService.getRefreshTokenSubject();
  }

  ngOnInit(): void {
    this.translate.get('OAUTH.LOGIN').subscribe(value => {
      this.loginDescription = value;
    });
    this.route.queryParams.subscribe({
      next: params => {
        console.log(params);
        if (params['code']) {
          this.authService.requestL1Token(params['code']).subscribe({
            next: () => {
              this.requestL3();
            }, error: err => {
              console.error(err);
              this.showError(err);
            }
          });
        } else {
          this.invalidCodeMessage = true;
          this.error = this.translate.instant('OAUTH.ERROR.INVALID_CODE');
        }
      }
    });
  }

  showError(err: any) {
    this.loading = false;
    err.status != null ? this.error = err.status : this.error = err;
    throw err;
  }

  private requestL3() {
    this.authService.requestL3Token().subscribe({
      next: () => {
        let navigateTo = sessionStorage.getItem(this.authService.NAVIGATE_AFTER_LOGIN);
        if (navigateTo) {
          sessionStorage.removeItem(this.authService.NAVIGATE_AFTER_LOGIN);
          this.router.navigateByUrl(navigateTo);
        } else {
          this.router.navigate(['']);
        }
      },
      error: err => {
        if (err.status === 401 || err.status === 400) {
          if (!this.tokenRefreshService.isRefreshTokenPending()) {
            console.log('401 - in Oauth l3 request - starting refresh...');
            this.tokenRefreshService.startRefresh();
          }
          this.refreshTokenSubject.pipe(
            filter(token => token !== null),
            take(1))?.subscribe(() => {
            this.requestL3();
          });
        } else {
          this.showError(err);
        }
      }
    });

  }
}
