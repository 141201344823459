import {environment} from "../../environments/local/environment";

export enum ApplicationIds {
  HPLUS_SERVICE = 'ecf1ee33-d7af-4857-b8d3-1c8f5f9cabb8',
  LEVEL_2 = '90ddec43-eae9-42bb-b329-5e062d788cc6',
  CUSTOMER_PORTAL = '9590586f-1e4a-4ff3-b02e-70eeedc716bb',
  ELECTRICITY_CALCULATION_TOOL = '4dc5c7e7-fd0c-4233-adf1-be3e912f8259',
  TENDER_TOOL = '54498c32-fdb8-4702-8535-236864632a4c',
  HPLUS_DONGLE = '612a7bca-15a3-48fd-b9c6-db13b8ab41ce',
  REMOTE_ADMIN = '4dbb96a9-5f35-4b33-81d3-f78349583f2b',
  REMOTE_DESKTOP = '9a598a24-4251-4d22-b401-e0caf6c37784',
  OMS_TOOL = '6a01c6bb-e429-4949-bbfb-98df07bc3ac9'
}

export type ApplicationDTO = {
  id: ApplicationIds;
  // name: string; // cannot be used from backend because it is not always up to date
  // description: string; // cannot be used from backend because it is not always up to date
}

export class ApplicationSelection {

  constructor(app: ApplicationDTO, selected: boolean) {
    this.application = app;
    this.selected = selected;
  }

  application: ApplicationDTO;
  selected: boolean = false;
}

export interface IAppConfig {
  appId: ApplicationIds;
  translationTitle: string;
  translationContent: string;
}

export const APP_CONFIGS: IAppConfig[] = [
  {
    appId: ApplicationIds.HPLUS_SERVICE,
    translationTitle: 'APPS.SERVICE.TITLE',
    translationContent: 'APPS.SERVICE.DESCRIPTION'
  },
  {
    appId: ApplicationIds.CUSTOMER_PORTAL,
    translationTitle: 'APPS.CUSTOMER_PORTAL.TITLE',
    translationContent: 'APPS.CUSTOMER_PORTAL.DESCRIPTION'
  },
  {
    appId: ApplicationIds.LEVEL_2,
    translationTitle: 'APPS.LEVEL_2.TITLE',
    translationContent: 'APPS.LEVEL_2.DESCRIPTION'
  },
  {
    appId: ApplicationIds.ELECTRICITY_CALCULATION_TOOL,
    translationTitle: 'APPS.ELECTRICITY_CALCULATION_TOOL.TITLE',
    translationContent: 'APPS.ELECTRICITY_CALCULATION_TOOL.DESCRIPTION'

  },
  {
    appId: ApplicationIds.TENDER_TOOL,
    translationTitle: 'APPS.TENDER_TOOL.TITLE',
    translationContent: 'APPS.TENDER_TOOL.DESCRIPTION'

  },
  {
    appId: ApplicationIds.HPLUS_DONGLE,
    translationTitle: 'APPS.HPLUS_DONGLE.TITLE',
    translationContent: 'APPS.HPLUS_DONGLE.DESCRIPTION'
  },
  {
    appId: ApplicationIds.REMOTE_ADMIN,
    translationTitle: 'APPS.REMOTE_ADMIN.TITLE',
    translationContent: 'APPS.REMOTE_ADMIN.DESCRIPTION'
  },
  {
    appId: ApplicationIds.REMOTE_DESKTOP,
    translationTitle: 'APPS.REMOTE_DESKTOP.TITLE',
    translationContent: 'APPS.REMOTE_DESKTOP.DESCRIPTION'
  },
  {
    appId: ApplicationIds.OMS_TOOL,
    translationTitle: 'APPS.OMS_TOOL.TITLE',
    translationContent: 'APPS.OMS_TOOL.DESCRIPTION'
  }
];
