import {NgModule} from '@angular/core';
import {OauthComponent} from "./oauth/oauth.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {TranslateModule} from "@ngx-translate/core";
import {MatButtonModule} from "@angular/material/button";
import {GlobalLoadingComponent} from "./loading/global-loading/global-loading.component";
import {CommonModule} from "@angular/common";
import {L2MenuComponent} from "./l2-menu/l2-menu.component";
import {NgxHekaDesignLibModule} from "ngx-heka-design-lib";
import {RouterModule} from "@angular/router";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {DirectivesModule} from "../directives/directives.module";
import {ApplicationImgComponent} from "./application-img/application-img.component";


@NgModule({
  declarations: [
    OauthComponent,
    GlobalLoadingComponent,
    L2MenuComponent,
    ApplicationImgComponent
  ],
  exports: [
    L2MenuComponent,
    ApplicationImgComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    TranslateModule,
    MatButtonModule,
    NgxHekaDesignLibModule,
    RouterModule,
    MatIconModule,
    MatMenuModule,
    DirectivesModule
  ]
})
export class ComponentsModule {
}
