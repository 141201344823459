import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgProgressModule} from "ngx-progressbar";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import {registerLocaleData} from "@angular/common";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import localeDe from '@angular/common/locales/de';
import {MatPaginatorIntl} from "@angular/material/paginator";
import {getGermanPaginatorIntl} from '../assets/i18n/german-paginator-intl';
import {PipesModule} from "./pipes/pipes.module";
import {OAuthModule, OAuthStorage} from "angular-oauth2-oidc";
import {ComponentsModule} from "./components/components.module";
import {GlobalErrorHandler} from "./services/error-handling/global-error-handler";
import {NgProgressHttpModule} from "ngx-progressbar/http";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";
import {MAT_CHIPS_DEFAULT_OPTIONS} from "@angular/material/chips";
import {COMMA, SPACE} from "@angular/cdk/keycodes";
import {TokenInterceptor} from "ngx-auth-util-lib";
import {MatSnackBarModule} from "@angular/material/snack-bar";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

registerLocaleData(localeDe);

export function appInitializerFactory(translate: TranslateService) {
  return () => {
    translate.setDefaultLang('de');
    return translate.use('de').toPromise();
  };
}
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    OAuthModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    MatSnackBarModule,
    ComponentsModule,
    PipesModule,
    NgProgressHttpModule,
    NgProgressModule.withConfig({
      color: '#004799',
      thick: true
    }),
    NgProgressModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'de'
    },
    {provide: MatPaginatorIntl, useValue: getGermanPaginatorIntl()},
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {provide: OAuthStorage, useValue: localStorage},
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline', subscriptSizing: 'dynamic'}},
    {provide: MAT_CHIPS_DEFAULT_OPTIONS, useValue: {separatorKeyCodes: [COMMA, SPACE]}}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
